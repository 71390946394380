import { Link } from "gatsby"
import styled from "styled-components"

export const Heading48 = styled.h1`
  font-family: Montserrat;
  font-weight: 700;

  @media only screen and (min-width: 1441px) {
    font-size: 48px;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 1365px) {
    font-size: 32px;
  }
`

export const Heading40 = styled.h1`
  font-family: Montserrat;
  font-weight: 700;

  @media only screen and (min-width: 1441px) {
    font-size: 40px;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 1365px) {
    font-size: 24px;
  }
`

export const Heading32 = styled.h1`
  font-family: Montserrat;
  font-weight: 700;

  @media only screen and (min-width: 1441px) {
    font-size: 32px;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 1365px) {
    font-size: 24px;
  }
`

export const Heading24 = styled.h1`
  font-family: Montserrat;
  font-weight: 700;

  @media only screen and (min-width: 1441px) {
    font-size: 24px;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 1365px) {
    font-size: 20px;
  }
`

export const Heading20 = styled.h1`
  font-family: Montserrat;
  font-weight: 700;

  @media only screen and (min-width: 1441px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 1365px) {
    font-size: 18px;
  }
`

export const Paragraph18 = styled.p`
  font-family: Montserrat;
  font-weight: 400;
  letter-spacing: 0.06em;

  p {
    font-family: Montserrat;
    font-weight: 400;
    letter-spacing: 0.06em;
    margin: 0 !important;
  }

  @media only screen and (min-width: 1441px) {
    font-size: 18px;

    p {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 14px;

    p {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1365px) {
    font-size: 16px;

    p {
      font-size: 16px;
    }
  }
`

export const Paragraph16 = styled.p`
  font-family: Montserrat;
  font-weight: 400;
  letter-spacing: 0.06em;

  p {
    font-family: Montserrat;
    font-weight: 400;
    letter-spacing: 0.06em;
    margin: 0 !important;
  }

  @media only screen and (min-width: 1441px) {
    font-size: 16px;

    p {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 14px;

    p {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1365px) {
    font-size: 16px;

    p {
      font-size: 16px;
    }
  }
`

export const StyledButton = styled(Link)`
  all: unset;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 42px;
  border-radius: 20px;
  background: ${(props) => props.color || "#0073AF"};
  color: #ffffff;
  width: fit-content;

  &:hover {
    color: #ffffff;
  }

  p {
    font-family: Montserrat;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.06em;
    color: #ffffff;
    margin: 0 !important;
    white-space: nowrap;

    @media only screen and (max-width: 1367px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`
