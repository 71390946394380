import React, { useEffect } from "react"
import styled from "styled-components"
import useWindowSize from "../../WindowDimensions"
import { graphql, useStaticQuery } from "gatsby"

const HeroHeader = ({ scrollRef }) => {
  const { width } = useWindowSize()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleScrollDown = () => {
    scrollRef.current.scrollIntoView()
  }

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "home" } }) {
        frontmatter {
          videoHeader {
            publicURL
            internal {
              mediaType
            }
          }
          videoHeaderFallback {
            publicURL
            internal {
              mediaType
            }
          }
          mobileVideoHeader {
            publicURL
            internal {
              mediaType
            }
          }
        }
      }
    }
  `)

  const { videoHeader, videoHeaderFallback, mobileVideoHeader } =
    data.markdownRemark.frontmatter

  return (
    <HeroContainer>
      <Background>
        {width <= 915 && (
          <Video autoPlay muted loop playsInline>
            <source
              src={mobileVideoHeader.publicURL}
              type={mobileVideoHeader.internal.mediaType}
            />
          </Video>
        )}
        {width > 915 && (
          <Video autoPlay muted loop playsInline>
            <source
              src={videoHeader.publicURL}
              type={videoHeader.internal.mediaType}
            />
            <source
              src={videoHeaderFallback.publicURL}
              type={videoHeaderFallback.internal.mediaType}
            />
          </Video>
        )}
      </Background>
      {width > 915 && (
        <ScrollButton onClick={handleScrollDown}>
          <div>
            <span></span>
          </div>
        </ScrollButton>
      )}
    </HeroContainer>
  )
}

export default HeroHeader

const HeroContainer = styled.div`
  display: flex;
  height: 91vh;
  position: relative;

  @media only screen and (max-width: 1366px) and (min-width: 1024px) {
    height: 86vh;
  }

  @media only screen and (max-width: 1250px) and (min-width: 915px) {
    height: 65vh;
  }

  @media only screen and (max-width: 915px) {
    height: 100%;
  }
`

const Background = styled.div`
  position: absolute;
  top: 90px;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 915px) {
    position: relative;
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 90%;

  @media only screen and (max-width: 915px) {
    height: auto;
  }
`

const ScrollButton = styled.div`
  width: 100%;
  position: relative;
  div span {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 46px;
    height: 46px;
    margin-left: -23px;
    background: #fff;
    border-radius: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }

  div span::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 16px;
    height: 16px;
    margin: -12px 0 0 -8px;
    border-left: 2px solid #0073af;
    border-bottom: 2px solid #0073af;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
  }

  div span::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    content: "";
    width: 44px;
    height: 44px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    opacity: 0;
    -webkit-animation: sdb03 3s infinite;
    animation: sdb03 3s infinite;
    box-sizing: border-box;
  }

  @keyframes sdb03 {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`
