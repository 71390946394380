import React, { useRef } from "react"
import { graphql } from "gatsby"
import HeroHeader from "../components/english/home/HeroHeader"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import HomeContent from "../components/english/home/HomeContent"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"

const IndexPage = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
  const scrollRef = useRef(null)

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout
        seoTitle="Home"
        seoKeywords="EPC Company in Indonesia, Best EPC Company in Indonesia, Japanese EPC Company in Indonesia, Japanese quality, Global Company, JFE locations, Japanese EPC, JFE Engineering Indonesia Employees, JFEEI Employees, JFE Engineering Indonesia, JFEEI, PT. JFEEI, JFEEI Events, Blood Donor, Employee Gathering, Ramadhan, Sports, Social, JFE Engineering Indonesia Privacy Policy, JFEEI Privacy Policy"
      >
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <HeroHeader scrollRef={scrollRef} />
        <HomeContent scrollRef={scrollRef} />
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
